<template>
  <BreadcrumbsAtom>
    <div class="breadcrumb" v-for="(breadcrumb, i) in breadcrumbs" :key="breadcrumb.id">
      <ChevronsRightIcon v-if="i > 0" />
      <router-link :to="breadcrumb.to">{{ breadcrumb.label }}</router-link>
    </div>
  </BreadcrumbsAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { ChevronsRightIcon } from 'vue-feather-icons'

const BreadcrumbsAtom = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  > .breadcrumb {
    display: flex;
    align-items: center;
    .feather {
      margin-right: 0.5rem;
    }
  }
`

export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  components: {
    BreadcrumbsAtom,
    ChevronsRightIcon,
  },
}
</script>
