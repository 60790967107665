var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('SearchAtom', [_c('input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('actions.search')
    },
    domProps: {
      "value": _vm.search
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event.target.value);
      },
      "focus": function focus($event) {
        return _vm.$emit('focus');
      },
      "blur": function blur($event) {
        return _vm.$emit('blur');
      }
    }
  }), _c('SearchIcon', {
    class: {
      'is-searching': _vm.isSearching
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }