var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('MarketplaceApp', [_c('header', [_c('BreadcrumbsAtom', {
    attrs: {
      "breadcrumbs": _vm.breadcrumbs
    }
  }), _vm.isProductCatalog ? _c('SearchAtom', {
    attrs: {
      "search": _vm.search,
      "isSearching": _vm.isSearching
    },
    on: {
      "input": _vm.handleSearchInput
    }
  }) : _vm._e()], 1), _c('router-view', {
    attrs: {
      "search": _vm.search
    },
    on: {
      "set-is-searching": function setIsSearching($event) {
        _vm.isSearching = $event;
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }