var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BreadcrumbsAtom', _vm._l(_vm.breadcrumbs, function (breadcrumb, i) {
    return _c('div', {
      key: breadcrumb.id,
      staticClass: "breadcrumb"
    }, [i > 0 ? _c('ChevronsRightIcon') : _vm._e(), _c('router-link', {
      attrs: {
        "to": breadcrumb.to
      }
    }, [_vm._v(_vm._s(breadcrumb.label))])], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }