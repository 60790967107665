<template>
  <MarketplaceApp>
    <header>
      <BreadcrumbsAtom :breadcrumbs="breadcrumbs" />
      <SearchAtom v-if="isProductCatalog" :search="search" :isSearching="isSearching" @input="handleSearchInput" />
    </header>
    <router-view :search="search" @set-is-searching="isSearching = $event" />
  </MarketplaceApp>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { debounce } from 'vue-debounce'

import BreadcrumbsAtom from './MarketplaceApp/BreadcrumbsAtom'
import SearchAtom from './MarketplaceApp/SearchAtom'

import PRODUCT_QUERY from '#/graphql/marketplace/productQuery.gql'

const MarketplaceApp = styled('div')`
  margin: 1rem max(1rem, calc(50% - 400px));
  width: min(calc(100% - 2rem), 800px);
  height: max(calc(100% - 2rem), 400px);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  grid-gap: 3px;
  overflow: hidden;
  border-radius: 0.5rem;

  > header {
    display: flex;
    height: 2.5rem;
    justify-content: space-between;
    padding: 1rem;
    background: ${({ theme }) => theme.colors.solidBG};
  }

  > main {
    overflow: auto;
  }
`

export default {
  components: {
    MarketplaceApp,
    BreadcrumbsAtom,
    SearchAtom,
  },
  data() {
    return {
      search: '',
      isSearching: false,
      product: null,
    }
  },
  created() {
    this.triggerSearch = debounce(params => {
      this.executeSearch(params)
    }, 500)
  },
  computed: {
    isProductCatalog() {
      return this.$route?.name === 'ProductCatalog'
    },
    breadcrumbs() {
      const breadcrumbs = [
        {
          id: 'ProductCatalog',
          to: {
            name: 'ProductCatalog',
          },
          label: this.$t('navigation.cockpit.marketplace'),
        },
      ]
      if (!this.isProductCatalog && this.product?.id) {
        breadcrumbs.push({
          id: 'ProductDetails',
          to: {
            name: 'ProductDetails',
            params: {
              id: this.product.id,
            },
          },
          label: this.product.name,
        })
      }
      return breadcrumbs
    },
  },
  watch: {
    $route: {
      handler() {
        this.search = this.$route?.query?.search ?? ''
      },
      immediate: true,
    },
  },
  methods: {
    handleSearchInput(e) {
      this.triggerSearch(e.trim())
    },
    executeSearch(params) {
      if (this.$route?.query?.search !== params) {
        this.$router.push({
          query: {
            ...this.$route?.query,
            search: params.length > 0 ? params : undefined,
          },
        })
      }
    },
  },
  apollo: {
    product: {
      query: PRODUCT_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      skip() {
        return this.$route?.params.id === undefined
      },
    },
  },
}
</script>
