<template>
  <SearchAtom>
    <input
      type="text"
      :value="search"
      :placeholder="$t('actions.search')"
      @input="$emit('input', $event.target.value)"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    />
    <SearchIcon :class="{ 'is-searching': isSearching }" />
  </SearchAtom>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { SearchIcon } from 'vue-feather-icons'
import chroma from 'chroma-js'

const SearchAtom = styled('div')`
  position: relative;
  width: 50%;
  min-width: 12rem;
  > input {
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    box-sizing: border-box;
    background: ${({ theme }) => (theme.isDark ? chroma(theme.colors.black).alpha(0.25).css() : theme.colors.lightestGrey)};
    border: none;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem 0.25rem 2.5rem;
    color: ${({ theme }) => theme.colors.primary};
    outline: none;
  }

  @keyframes searching {
    from {
      color: ${({ theme }) => theme.colors.primary};
      transform: scale(1);
    }
    to {
      color: transparent;
      transform: scale(0.75);
    }
  }
  > .feather-search {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: ${({ theme }) => (theme.isDark ? theme.colors.mediumGrey : theme.colors.lightGrey)};
    &.is-searching {
      animation-name: searching;
      animation-duration: 0.5s;
      animation-iteration-count: infinite;
      animation-direction: alternate-reverse;
    }
  }
`

export default {
  props: {
    isSearching: {
      type: Boolean,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  components: {
    SearchAtom,
    SearchIcon,
  },
}
</script>
